.addContainer {
  background: white;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 2px #aab2bd;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 0px 3px 2px #aab2bd;
}

.addH4 {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.queContainer {
  background-color: #f2f2f2;
  margin-left: 15%;
  margin-right: 15%;
}

.queH3 {
  font-size: 24px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 70px;
  margin-bottom: 20px;
  color: #797979;
}

.queH3 .queH3Arrow {
  font-size: 16px;
}

.queSubContainer {
  background-color: white;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 2px #aab2bd;
}

.queH4 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #797979;
}

.queContant {
  width: 75%;
  height: 480px;
  margin: 20px auto;
  margin-bottom: 30px;
}

.uploadedPDFDisplay {
  height: 660px;
}

.uploadedImageDisplay {
  height: 550px;
}

.queContant canvas {
  width: 100% !important;
  height: 700px !important;
}

.uploadedImage {
  width: 100% !important;
  height: 500px !important;
}

.quePage {
  text-align: center;
  font-weight: bold;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 15px;
  margin: 10px;
}

.queContainerButtons {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.queAdd {
  background: #68dff0;
  color: white;
  padding: 5px 12px;
  margin-right: 16%;
  border-radius: 4px;
  border: 1px solid #64c3c2 !important;
}
.queAdd:hover {
  background: #51cac2;
}

.queArrowLeft {
  background: #68dff0;
  color: white;
  padding: 4px 2px 4px 10px;
  margin-right: 2%;
  border-radius: 4px;
  border: 1px solid #64c3c2 !important;
}
.queArrowLeft:disabled {
  cursor: not-allowed;
  background: #b7e8f0;
  border: 1px solid #b7e8f0 !important;
}

.queArrowRight {
  background: #68dff0;
  color: white;
  padding: 5px 7px;
  border: none;
  /* margin-right: 18%; */
  border-radius: 4px;
  border: 1px solid #64c3c2 !important;
}
.queArrowRight:disabled {
  cursor: not-allowed;
  background: #b7e8f0;
  border: 1px solid #b7e8f0 !important;
}

.queDelet {
  background: #f0ad4e;
  color: white;
  padding: 8px;
  border: none;
  margin-right: 0%;
  border-radius: 4px;
  border: 1px solid #eea236 !important;
}

.questionCopyHead {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  padding-right: 2%;
  width: 20%;
  padding-left: 8.2%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.questionCopyBtn {
  height: 32px;
  width: 52.2%;
  box-sizing: border-box;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 5.5%;
  margin-right: 8%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  cursor: pointer;
}

.questionCopyTextarea {
  height: 80px;
  padding: 10px;
  width: 52.2%;
  box-sizing: border-box;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 5.5%;
  margin-right: 8%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
}

.compulsoryContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.compulsoryHeading {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Ruda", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.compulsorySubContainer {
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.compulsoryDropdown {
  width: 100%;
  margin-bottom: 20px;
}

.moreContainer {
  background: #ffffff;
  padding: 14px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 2px #aab2bd;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 0px 3px 2px #aab2bd;
}

.moreH4 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Ruda", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.moreSubContainer {
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.moreDropdown {
  width: 100%;
  margin-bottom: 20px;
}

.moreCheckbox {
  margin-left: 20%;
}

.addLabel {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.addLabel::after {
  content: "*";
  color: #797979;
  margin-left: 2px;
}

.addLabelRed {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  color: red;
}

.addLabelRed::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.addInput {
  display: block;
  width: 98%;
  height: auto;
  outline-color: #68dff0;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-bottom: 20px;
}

.addOpt {
  margin-bottom: 5px;
  margin-left: 8%;
}

.addFile {
  margin-bottom: 20px;
  color: #fff;
  background-color: #68dff0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.addSubmit {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
}
.addSubmit:hover {
  background-color: #51cac2;
}

.addSubmit:disabled {
  background-color: #acafaf;
  cursor: not-allowed;
  border: none;
}

.addInputSmall {
  height: 32px;
  width: 52%;
  box-sizing: border-box;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.addInputFile {
  max-height: 22px;
  width: 36.8%;
  margin-left: 4%;
  margin-right: 4%;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  height: 32px;
  width: 52.4%;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4% !important;
  margin-right: 11% !important;
  border-radius: 4px;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px !important;
}

.uploadButton {
  max-height: 36px;
  width: 8.2%;
  color: #f2f2f2;
  font-size: 14px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #64c3c2 !important;
  padding: 8px 0.5%;
  background: #68dff0;
  cursor: pointer;
  margin-right: 11%;
}

.shortDescription {
  margin-left: 9%;
  margin-right: 9%;
  width: 77.5%;
  margin-bottom: 20px;
}

.sun-editor {
  width: 68.5% !important;
  display: block !important;
  margin-left: 207px !important;
}

.modal_close {
  margin-left: 82% !important;
  margin-top: 30px !important;
}

.editorBtn {
  height: 32px;
  width: 52.4%;
  box-sizing: border-box;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  cursor: pointer;
}

.editorBtn:hover {
  background-color: rgb(202, 200, 200);
}

.editorBtn:active {
  border: 2px solid #555;
}

.contentText {
  height: auto;
  width: auto;
  margin: 15px;
  background-color: #f7f3f3;
  padding: 6px;
  margin-left: 8%;
  margin-right: 12%;
  font-size: 11px;
  border-radius: 10px;
}

.addCheckbox {
  height: 22px;
  width: 3.2%;
  box-sizing: border-box;
  position: relative;
  max-height: 35px;
  margin-left: 4%;
  margin-right: 11%;
}

.fileChooseRespone {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  font-family: "Ruda", sans-serif;
  margin-left: 4%;
  margin-right: 11%;
  margin-top: 3px;
  border-radius: 4px;
  outline-color: #68dff0;
  margin-bottom: 5px;
  color: #fff;
  background-color: #68dff0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-image: none;
  border: 1px solid #48bcb4;
  padding: 6px 12px;
  cursor: pointer;
}

.btnDivMatching {
  display: flex;
  justify-content: center;
}

.btnResponse {
  border-radius: 50%;
  color: #f2f2f2;
  font-size: 24px;
  border: none;
  padding: 2px 12px;
  margin-right: 15px;
  background: #68dff0;
  margin-top: 15px;
  cursor: pointer;
}
.btnResponse:hover {
  background-color: #51cac2;
}

.btnResponse:active {
  border: 1px solid #03afa9 !important;
}

.btnResponseMinus {
  border-radius: 50%;
  color: #f2f2f2;
  border: none;
  font-size: 30px;
  padding: 3px 14px;
  margin-right: 15px;
  background: #68dff0;
  margin-top: 15px;
  cursor: pointer;
}
.btnResponseMinus:hover {
  background-color: #51cac2;
}

.btnResponseMinus:active {
  border: 1px solid #03afa9 !important;
}

.btnFilesUpload {
  color: #f2f2f2;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  margin-right: 15px;
  background: #68dff0;
  cursor: pointer;
  max-height: 35px;
  margin-top: 3px;
}
.btnFilesUpload:hover {
  background-color: #51cac2;
}

.btnFilesUpload:active {
  border: 1px solid #03afa9 !important;
}

.searchQuestionLabelMat {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  margin-left: 2%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.fileChooseAddDetails {
  box-sizing: border-box;
  width: 41%;
  position: relative;
  max-height: 35px;
  font-family: "Ruda", sans-serif;
  margin-left: 5.5%;
  margin-right: 2.5%;
  border-radius: 4px;
  outline-color: #68dff0;
  color: #fff;
  background-color: #68dff0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #48bcb4;
  padding: 6px 12px;
  cursor: pointer;
}

.btnFilesUploadAddDetails {
  color: #f2f2f2;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  margin-right: 88px;
  background: #68dff0;
  cursor: pointer;
  max-height: 35px;
}
.btnFilesUploadAddDetails:hover {
  background-color: #51cac2;
}

.btnFilesUploadAddDetails:active {
  border: 1px solid #03afa9 !important;
}

.dropdownLabelHint {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  margin-left: 9%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.solutionDropdownDiv {
  display: flex;
  margin: 30px 0 30px 0;
}

.solutionCopyHead {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  padding-right: 2%;
  width: 20%;
  padding-left: 9%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.searchQuestionSelectText {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  height: 80px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.addLabelDescripton {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.compulsoryContainerMAQ {
  width: 30%;
  margin-left: 35%;
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-right: 15%;
}

.searchQuestionLabelMAQ {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.fileCopyUploaded {
  margin-top: -10px;
  margin-left: 36%;
  font-size: 12px;
  color: #797979;
  font-family: "Ruda", sans-serif;
}
.fileCopyUploadedSpin {
  height: 25px !important;
  width: 25px !important;
  margin-top: -10px;
  margin-left: 36%;
}

.fileCopyUploadedText {
  margin-left: 40%;
  margin-top: -17px;
  font-size: 12px;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.fileSolUploaded {
  margin-top: -22px;
  margin-left: 37%;
  font-size: 12px;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.fileSolUploadedSpin {
  height: 25px !important;
  width: 25px !important;
  margin-top: -20px;
  margin-left: 38%;
}

.fileSolUploadedText {
  margin-left: 42%;
  margin-top: -20px;
  font-size: 12px;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.fileImageUploaded {
  margin-top: 2px;
  margin-left: 33%;
  font-size: 12px;
  color: #797979;
  font-family: "Ruda", sans-serif;
}
.fileResUploadedSpin {
  height: 25px !important;
  width: 25px !important;
  margin-top: 0px;
  margin-left: 33%;
}

.fileResUploadedText {
  margin-left: 39%;
  margin-top: -18px;
  font-size: 12px;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.AddDetailsLoadingDiv {
  display: inline-flex;
  height: 30px;
  width: 290px;
}

.addDetailsLoadingDivErr {
  display: inline-flex;
  height: 30px;
  width: 800px;
}

.loadingDivErr {
  display: inline-flex;
  height: 30px;
  position: absolute;
  width: 800px;
}

.loadingTextDataErr {
  width: auto;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 4%;
  margin-left: 15px;
}

.startLoadSpin {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150px !important;
  margin-left: -100px;
  height: 50px;
  margin-top: -25px;
  z-index: 100;
}

.loadingBackground {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 99;
}

.compulsoryContainerCheckboxSubmit {
  display: flex;
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.addDetailsCuratedDiv {
  display: flex;
  margin-right: 30px;
}

.addDetailsCuratedP {
  margin: 8px 0px 0px 10px;
  font-size: 16px;
  font-weight: 500;
}

.addReject {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 9%;
}

.curatedWarning {
  /* position: sticky;
  top: 61px;
  padding: 0px 37%;
  width: 100%;
  background: #e50808;
  margin-left: -8px;
  z-index: 10; */

  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 61px;
  width: 100%;
  background: #e50808;
  z-index: 10;
  min-height: 40px;
}

.curatedWarningP {
  font-weight: 600;
}

.docxFileDiv {
  width: 75%;
  margin: 15px auto;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  margin-bottom: 30px;
  border: 2px solid #d9d2d2;
  border-radius: 5px;
}

.pg-viewer-wrapper {
  width: 103%;
}
