.startLoadSpinQSetRep {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150px !important;
  margin-left: -100px;
  height: 50px;
  margin-top: -25px;
  z-index: 1;
}

.qSetReportTableContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.download-report-btn {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
  width: 90px;
  margin-left: auto;
}
