.translateContainer {
  background-color: #f2f2f2;
  margin-left: 15%;
  margin-right: 15%;
}

.translateH3 {
  font-size: 24px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 70px;
  margin-bottom: 20px;
  color: #797979;
}

.translateH3 .translateH3Arrow {
  font-size: 16px;
}

.searchTranslateContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.searchTranslateHeading {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Ruda", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.fileUploadTrans {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.uploadTransH4 {
  font-size: 16px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #797979;
  margin-top: 15px;
  margin-left: 8%;
  width: 213px;
}

.uploadTransPDFIcon {
  font-size: 24px;
  padding-top: 0px;
  vertical-align: middle;
}

.fileChooseTrans {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  font-family: "Ruda", sans-serif;
  margin-left: 4%;
  margin-right: 11%;
  /* margin-top: 15px; */
  border-radius: 4px;
  outline-color: #68dff0;
  /* margin-bottom: 10px; */
  color: #fff;
  background-color: #68dff0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #48bcb4;
  padding: 6px 12px;
  cursor: pointer;
}

.thumbnailContainerTrans {
  padding: 5px;
  position: relative;
  left: 38.4%;
  width: 60%;
}

.transButtonContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.transButtonSearch {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
}
.transButtonSearch:hover {
  background-color: #51cac2;
}

.transButtonSearch:active {
  background-color: #327e79;
}

.fileChooseTransNot {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  font-family: "Ruda", sans-serif;
  margin-left: 4.3%;
  margin-right: 11%;
  /* margin-top: 15px; */
  border-radius: 4px;
  outline-color: #68dff0;
  /* margin-bottom: 10px; */
  color: #fff;
  background-color: #68dff0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #48bcb4;
  padding: 6px 12px;
  cursor: not-allowed;
}

.dropdownLabelTrans {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  margin-left: 9%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.dropdownSelectTrans {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.loadingDivTrans {
  display: inline-flex;
  height: 30px;
  width: 290px;
  position: absolute;
}

.loadingTextDataTrans {
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 4%;
  width: 180px;
  margin-left: 15px;
}
