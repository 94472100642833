.card_wraper {
  display: flex;
  margin-top: 10px;
  gap: 5px;
  flex-wrap: wrap;
}
.card_wraper > div {
  padding: 8px;
  border: 1px solid gray;
  border-radius: 5px;
  background: #f3f1f166;
}
.card_wraper span {
  background: #ecebeb;
  padding: 4px 5px;
  border: 1px solid rgb(204, 203, 203);
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}
