.uploadH4 {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #797979;
  margin-top: 25px;
  margin-left: 8%;
  width: 213px;
}

.uploadPDFIcon {
  font-size: 24px;
  padding-top: 0px;
  vertical-align: middle;
}

.fileUploadQue {
  display: flex;
}

.dropdownDivQue {
  display: flex;
  margin-top: 5px;
}

.dropdownLabelQue {
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 4%;
  width: 180px;
  margin-left: 8%;
}

.dropdownSelectQue {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 3.6%;
  margin-right: 10.8%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.fileChooseQue {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  font-family: "Ruda", sans-serif;
  margin-left: 4%;
  margin-right: 11%;
  margin-top: 25px;
  border-radius: 4px;
  outline-color: #68dff0;
  margin-bottom: 20px;
  color: #fff;
  background-color: #68dff0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #48bcb4;
  padding: 6px 12px;
  cursor: pointer;
}

.fileChooseQueNot {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  font-family: "Ruda", sans-serif;
  margin-left: 4%;
  margin-right: 11%;
  margin-top: 25px;
  border-radius: 4px;
  outline-color: #68dff0;
  margin-bottom: 20px;
  color: #fff;
  background-color: #68dff0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #48bcb4;
  padding: 6px 12px;
  cursor: not-allowed;
}

canvas {
  width: 150px !important;
  height: 150px !important;
}

.thumbnailContainerUpload {
  padding: 5px;
  position: relative;
  left: 38.4%;
  width: 60%;
}

.imgUploadThumbnail {
  border-radius: 8px;
  height: 200px;
  width: 250px;
}

.uploadFileText {
  height: 200px;
  width: 75%;
}

.loadingDiv {
  display: inline-flex;
  height: 30px;
  width: 290px;
  position: absolute;
}

.reactLoadingIcon {
  height: 25px !important;
  margin-left: 10px;
  width: 30px !important;
  margin-right: 18px;
}

.loadingText {
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 4%;
  width: 180px;
}
