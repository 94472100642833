.qSetViewTable {
  width: 100%;
  overflow-y: auto;
  border-spacing: 0px;
  max-height: 400px;
  /* border: 1px solid #797979; */
  border-collapse: collapse;
  /* font-weight: 500; */
}

.qSetViewTable tbody tr:hover {
  background-color: #cae0ee;
}

.qSetViewTable tbody tr:active {
  background-color: #71b9e9 !important;
}
.qSetViewTable tbody tr:visited {
  background-color: #71b9e9 !important;
}

.qSetViewTable tbody tr td {
  text-align: center;
  padding: 10px;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  cursor: pointer;
}

.qSetViewTable thead tr th {
  text-align: center;
  position: sticky !important;
  top: 0;
  background-color: #e5e5e5;
  color: #797979;
  padding: 15px 2px;
  /* width: 102px; */
}

.qSetViewTable th {
  border-bottom: 1px solid #797979;
  /* border-collapse: collapse; */
  font-weight: 700;
}

.qSetViewTable td {
  border-bottom: 1px solid #797979;
  /* border-collapse: collapse; */
  /* font-weight: 500; */
}

.qSetViewTable td:nth-child(3) {
  width: 120px;
}

.qSetViewTable td:nth-child(4) {
  width: 225px;
}

.selQSet {
  background-color: #cae0ee;
}

::-webkit-scrollbar {
  position: absolute;
  width: 12px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #dcdcdc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 5px;
}

.editQSet {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 4px 8px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 3px;
}

.deleteQSet {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 4px 8px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 3px;
}

.QSetViewTableContainer {
  height: 400px;
  margin: 0 3%;
  margin-bottom: 20px;
  overflow-y: auto;
  width: 92%;
}

.QSetTablePagination {
  min-width: 62%;
}

.qSetViewQuestiondiv {
  max-height: 500px;
  overflow: auto;
  margin-bottom: 20px;
}

.popUpBack {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 2;
}

.noPopUp {
  display: none;
}

.yesPopUp {
  position: fixed;
  top: 40%;
  left: 46%;
  width: 300px !important;
  margin-left: -100px;
  height: 150px;
  margin-top: -25px;
  background: white;
  /* border: solid 3px #d7d3d3; */
  z-index: 1;
  border-radius: 8px;
}

.popUpCloseBtn {
  position: relative;
  left: 270px;
  top: 1px;
  border: none;
  background-color: white;
  padding-right: 1px;
}

.closeIcon {
  font-size: 21px !important;
  color: gray;
}

.popUpCloseBtn:active {
  position: relative;
  left: 278px;
  top: 1px;
  border: none;
  background-color: rgb(189, 187, 187);
  font-weight: 700;
}

.warningDiv {
  display: flex;
  justify-content: center;
  margin-top: -17px;
}

.warningIcon {
  color: orange;
  font-size: 45px !important;
}

.popUph2 {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

.popUpButtonDiv {
  display: flex;
  justify-content: center;
}

.popUpOkButton {
  color: #f6f8f7;
  background: #68dff0;
  text-transform: capitalize;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
  height: 46px;
  width: 50%;
  border-radius: 0 0 5px 0;
}

.popUpOkButton:active {
  color: #f6f8f7;
  background: #50bbcc;
  text-transform: capitalize;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
  height: 46px;
  width: 50%;
  border-radius: 0 0 5px 0;
}

.popUpCancelButton {
  color: white;
  background: grey;
  text-transform: capitalize;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
  height: 46px;
  width: 50%;
  border-radius: 0 0 0 5px;
}

.popUpCancelButton:active {
  color: white;
  background: rgb(182, 181, 181);
  text-transform: capitalize;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
  height: 46px;
  width: 50%;
  border-radius: 0 0 0 5px;
}
