.dropdownQSetLabel {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 109px;
  margin-left: 1%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.dropdownQSetLabel::after {
  content: "*";
  color: #797979;
  margin-left: 2px;
}

.dropdownQSetLabelRed {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  margin-left: 9%;
  color: red;
  font-family: "Ruda", sans-serif;
}

.dropdownQSetLabelRed::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.dropdownQSetSelectNotReq {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 109px;
  margin-left: 1%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.dropdownQSetSelect {
  box-sizing: border-box;
  width: 57%;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 3px;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.dropdownQSetSelect:disabled {
  cursor: not-allowed;
}

.dropdownQSetDiv {
  display: flex;
}
