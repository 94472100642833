.qSetReportTableContainer table{
    border-collapse: collapse;
}

.qSetReportTableContainer thead tr {
  height: 40px;
}

.qSetReportTableContainer thead th {
  padding: 5px;
  color: #828282;
}

.qSetReportTableContainer thead {
  background-color: #f2f2f2;
}

.qSetReportThCourse {
  width: 10%;
}

.qSetReportThSubject {
  width: 12%;
}

.qSetReportThTopic {
  width: 16%;
}

.qSetReportTableContainer tbody tr {
  height: 30px;
  border-bottom: 1px solid #e0e0e0;
}

.qSetReportTableContainer tbody tr td {
  border-bottom: 1px solid #e0e0e0;
}
