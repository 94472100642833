.topicReportContainer {
  background-color: #f2f2f2;
  margin-left: 15%;
  margin-right: 15%;
}

.topicReportH3 {
  font-size: 24px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 70px;
  margin-bottom: 20px;
  color: #797979;
}

.topicReportH3 .topicReportArrow {
  font-size: 16px;
}

.topicReportSearchButtonContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.topicReportSearchBtn {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
}
.topicReportSearchBtn:hover {
  background-color: #51cac2;
}

.topicReportSearchBtn:active {
  background-color: #327e79;
}

.topicReportSearchContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.topicReportHeading {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Ruda", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.topicReportLabel {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  margin-left: 9%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.topicReportSelect {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.topicReportRadio {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  align-items: center;
}

.topicReportDropdownDiv {
  display: flex;
  margin-bottom: 20px;
}

.topicReportloadingDiv {
  display: inline-flex;
  height: 30px;
  width: 290px;
  position: absolute;
}

.topicRepReactLoadingIcon {
  height: 25px !important;
  margin-left: 10px;
  width: 30px !important;
  margin-right: 18px;
}

.topicRepLoadingTextData {
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 4%;
  width: 180px;
  margin-left: 20px;
}

.topRepCheckDiv {
  width: 100%;
  margin-bottom: 20px;
}

.topRepdropdown {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.topRepDropdownLabel {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  margin-left: 9%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.topicReportCheckbox {
  height: 22px;
  width: 3.2%;
  box-sizing: border-box;
  position: relative;
  max-height: 35px;
  margin-left: 4%;
  margin-right: 11%;
}
