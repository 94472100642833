.layoutButton {
  font-size: 20px;
  border: none;
  background-color: #ffd777;
  z-index: 11;
  padding-top: 9px;
  position: fixed;
  cursor: pointer;
}

.layoutContainer {
  display: flex;
  justify-content: left;
  flex-direction: row;
}

.togglebar {
  width: 210px;
  float: left;
  margin-right: 35px;

  background-color: black;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  min-height: 700px;
  position: fixed;
  margin-left: -8px;
}

.togglebarClose {
  width: 0;
}

.togg {
  margin-left: 220px;
}

.toggClose {
  margin-left: 0;
}

.layoutP {
  margin: 4px 0;
}

.developer {
  display: none;
}
