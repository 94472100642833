body {
  background-color: #f2f2f2;
  min-height: 100%;
  font-family: "Ruda", sans-serif;
}

.loginContainer {
  max-width: 330px;
  height: 50%;
  background-color: white;
  position: relative;
  left: 38%;
  text-align: center;
  margin-top: 12%;
  border-radius: 10px;
}

.loginH1 {
  background: #68dff0;
  color: #fff;
  padding: 25px 20px;
  text-align: center;
  background: #68dff0;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
}

.loginUsername {
  width: 86%;
  height: 34px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin: 2% 4% 2% 4%;
  padding-left: 10px;
}

.error {
  padding-left: 24px;
  text-align: left;
  font-size: 12px;
  padding-bottom: 5px;
  color: red;
}

.forgotPassword {
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  margin-left: 24px;
  margin-top: 10px;
  text-decoration: none;
}

.loginButton {
  background: #68dff0;
  color: #fff;
  border-color: #48bcb4;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 4px;
  width: 90%;
  margin: 4% 4% 5% 4%;
  cursor: pointer;
}
.loginButton:hover {
  background: #51cac2;
}
.loginButton:active {
  background: #098092;
  border: 1px solid #02343b;
}

.notMatching {
  font-size: 12px;
  color: red;
  display: block;
  text-align: left;
  margin-left: 26px;
  margin-top: 0px;
}
