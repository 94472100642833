.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  min-height: 60px;
  padding: 0 15px;
  border-bottom: 1px solid #c9aa5f;
  background: #ffd777;
  z-index: 10;
  font-family: "Ruda", sans-serif;
  padding-left: 28px;
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.navbarLogo {
  color: #ffffff;
  margin-left: 15px;
  text-transform: uppercase;
  text-decoration: none;
}
.navbarH1 {
  font-size: 22px;
  font-family: "Ruda", sans-serif;
  margin-bottom: 2px;
}

.navbarh2 {
  font-size: 12px;
  font-family: "Ruda", sans-serif;
}

.navbarSubContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.navbarLogout {
  color: #f2f2f2;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  margin-right: 15px;
  background: #68dff0;
  margin-top: 15px;
  cursor: pointer;
}
.navbarLogout:hover {
  background-color: #51cac2;
}

.navbarLogout:active {
  border: 1px solid #03afa9 !important;
}

.togglebar {
  width: 250px;
}

.togglebar1 {
  width: 0;
}
