.tableContainer {
  height: 740px;
  margin: 0 5%;
  margin-bottom: 20px;
  overflow-y: auto;
}

.backlogTable {
  height: 720px;
  overflow-y: auto;
  display: block;
  border-spacing: 0px;
}

.backlogTable tbody tr:hover {
  background-color: #cae0ee;
}

.backlogTable tbody tr:active {
  background-color: #71b9e9 !important;
}
.backlogTable tbody tr:visited {
  background-color: #71b9e9 !important;
}

.backlogTable thead tr th {
  text-align: center;
  padding: 10px;
  position: sticky;
  top: 0px;
  background-color: #e5e5e5;
  color: #797979;
  width: 172px;
}

.backlogTable tbody tr td {
  text-align: center;
  padding: 10px;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  cursor: pointer;
  color: #797979;
  border-bottom: 2px solid #e5e5e5 !important;
}

.backlogTable thead tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.questionId,  .backlogDeleteTh {
  width: 14% !important;
}

.backlogRejectTh{
  width: 26.6% !important;
}

.backlogImageContainer {
  height: 510px;
  display: flex;
  margin: 0 10%;
  justify-content: center;
}

.backlogImage {
  margin-right: 5%;
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.backlogDeleteBtn {
  max-height: 30px;
  color: #f2f2f2;
  font-size: 13px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  background: #68dff0;
  cursor: pointer;
  margin-bottom: 5px;
}

.backlogDeleteBtn:hover {
  background-color: #51cac2;
}

.backlogDeleteBtn:active {
  border: 1px solid #03afa9 !important;
}

.backlogButton {
  max-height: 35px;
  margin-top: 82px;
  color: #f2f2f2;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  background: #68dff0;
  cursor: pointer;
}

.backlogButton:hover {
  background-color: #51cac2;
}

.backlogButton:active {
  border: 1px solid #03afa9 !important;
}

.backlogTableButton {
  max-height: 45px;
  margin-top: 0px;
  color: #f2f2f2;
  font-size: 13px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  background: #68dff0;
  cursor: pointer;
}
.backlogTableButton:hover {
  background-color: #51cac2;
}

.backlogTableButton:active {
  border: 1px solid #03afa9 !important;
}

.backlogBigImage {
  width: 100%;
  height: 500px;
  border-radius: 10px;
  margin-top: 25px;
}

.queSubContainerBacklog {
  background-color: white;
  padding: 15px;
  padding-bottom: 30px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 2px #aab2bd;
}

.paginationContainerBacklog {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.peginationNumberDiv {
  display: flex;
}
