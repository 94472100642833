.checkbox-labelRight {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  display: block;
  position: relative;
  left: -4%;
  margin: auto;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  height: 28px;
  width: 4px;
  clear: both;
  font-weight: normal;
  font-family: "Ruda", sans-serif;
  padding-right: 55px;
  margin-right: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-top: 4px;
  padding-left: 18px;
  border: 1px solid #adadad;
  margin-left: 300px;
}

.checkbox-labelRight input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-labelRight .mark {
  top: -1px;
  left: -36px;
  position: absolute;
  height: 34px;
  width: 44px;
  background-color: red;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: all 0.3s ease-in;
}
.checkbox-labelRight input:checked ~ .mark {
  background-color: red;
  transform: rotate(0deg) scale(1);
  opacity: 1;
}
.checkbox-labelRight .mark::after {
  position: absolute;
  content: "";
  border-radius: 5px;
}
.checkbox-labelRight input:checked ~ .mark::after {
  transform: rotate(45deg) scale(1);
  left: 17px;
  top: 4px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 4px 4px 0;
  border-radius: 0;
}
