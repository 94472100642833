.toggleImage {
  border-radius: 50%;
  margin-top: 70px;
  height: 60px;
  text-align: center;
  margin-left: 76px;
}

.toggleH5 {
  color: #f2f2f2;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
}

.toggleQue {
  font-size: 16px;
  color: #aeb2b7;
  cursor: pointer;
  background-color: black;
  border: none;
  display: block;
  padding: 15px 105px 15px 10px;
}
.toggleQue:active,
.toggleQue:hover,
.toggleQue:focus {
  background-color: #68dff0;
  color: white;
}

.toggleBr {
  font-size: 5px;
}

.toggleQSet {
  font-size: 16px;
  color: #aeb2b7;
  cursor: pointer;
  background-color: black;
  border: none;
  display: block;
  padding: 15px 141px 15px 10px;
}
.toggleQSet:active,
.toggleQSet:hover,
.toggleQSet:focus {
  background-color: #68dff0;
  color: white;
}

.togglebar .bookIcon {
  font-size: 18px;
  margin-bottom: -4px;
}

.toggleLink {
  font-size: 14px;
  padding: 6px 0;
  line-height: 35px;
  height: 35px;
  color: #aeb2b7;
  text-decoration: none;
  font-family: "Ruda", sans-serif;
  margin-left: 44px;
}
.toggleLink:hover {
  color: white;
}
.toggleLink:active,
.toggleLink:focus {
  color: #68dff0;
}

.toggleLink1 {
  font-size: 14px;
  padding: 6px 0;
  line-height: 35px;
  height: 35px;
  color: #68dff0;
  text-decoration: none;
  font-family: "Ruda", sans-serif;
  margin-left: 44px;
}

/* .react-slidedown  */
.my-dropdown-slidedown {
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.toggleReport {
  font-size: 16px;
  color: #aeb2b7;
  cursor: pointer;
  background-color: black;
  border: none;
  display: block;
  padding: 15px 120px 15px 10px;
}
