.directAnswerMatching {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 35px;
}

.btnResponseMinusParti {
  border-radius: 14%;
  color: #f2f2f2;
  border: none;
  font-size: 12px;
  padding: 3px 7px;
  background: #68dff0;
  margin-top: 8px;
  cursor: pointer;
  max-height: 18px;
}

.searchQuestionLabelMatching {
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 10px;
  width: 15px;
  margin-left: 18%;
}

.matchingInput {
  box-sizing: border-box;
  width: 70%;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 2%;
  margin-right: 1%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
}

.matchingSelect {
  box-sizing: border-box;
  width: 50%;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 2%;
  margin-right: 4%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
}
