.setSaveLabelInputDiv {
  margin-bottom: 20px;
}

.setSaveLabelPDiv {
  display: flex;
}

.setSaveLabel {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  /* margin-left: 9%; */
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.setSaveInput {
  box-sizing: border-box;
  width: 30%;
  position: relative;
  height: 32px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
}

.viewSetContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  /* margin-left: 15%;
  margin-right: 15%; */
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}

/* .viewSearchOne {
  margin-left: 15.5%;
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
} */
.viewSetSearchTwo {
  margin-left: 15.5%;
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
}

.viewSetSearch:hover {
  background-color: #51cac2;
}

.viewSetSearch:active {
  background-color: #327e79;
}

.setSaveTable,
.setSaveTable th,
.setSaveTable td {
  border: 1px solid #797979;
  border-collapse: collapse;
  font-weight: 500;
  margin-bottom: 30px;
}

.setSaveTable th,
.setSaveTable td {
  padding: 10px;
  text-align: center;
}

.viewTableContainer {
  height: 740px;
  margin: 0 5%;
  margin-bottom: 20px;
  overflow-y: auto;
}

.qsetNumberDiv {
  width: 25px;
}

.qsetShortDiv {
  margin-left: 20px;
  width: 440px;
}

.qsetCheckbox {
  margin-top: 120%;
  margin-right: 10px;
}

.viewQSetTableContainer {
  height: 400px;
  margin: 0 3%;
  margin-bottom: 20px;
  overflow-y: auto;
  width: 45%;
}

.setRemoveButton {
  margin-top: 28px;
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 4px 8px;
  margin-bottom: 0;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.setRemoveButton:hover {
  background-color: #51cac2;
}
.setRemoveButton:active {
  border: 1px solid #03afa9 !important;
}

.summarySaveSetButton {
  margin-top: 28px;
  margin-left: 7px;
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 4px 8px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
}

.setSummaryNameDiv {
  width: 50%;
  /* margin-top: 100px; */
}

.setSaveSummaryP {
  margin-top: 10px;
  font-weight: 600;
}

.setSaveInputSummary {
  box-sizing: border-box;
  width: 57%;
  position: relative;
  height: 32px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
}

.setSaveLabelSummary {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 10px;
  width: 20%;
  max-width: 20%;
  margin-left: 5px;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.setSaveLabelSummary::after {
  content: "*";
  color: #797979;
  margin-left: 2px;
}

.setSaveLabelSummaryNoStar {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 10px;
  width: 20%;
  max-width: 20%;
  margin-left: 5px;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.setSaveTextareaSummary {
  box-sizing: border-box;
  width: 57%;
  position: relative;
  height: 50px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
}

.QSetCreatorSummaryDiv {
  width: 49%;
  height: 400px;
  overflow-y: auto;
}

.QSetCreatorh4PDiv {
  display: flex;
  flex-direction: row;
}

.qSetCreatorTotalMarksP {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Ruda", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-left: 100px;
}

.viewTopic {
  margin-left: 38px;
  margin-top: -5px;
}

.viewTablePaginationQSet {
  width: 48%;
}
