.subTopicReportTableContainer table{
    border-collapse: collapse;
}

.subTopicReportTableContainer thead tr {
  height: 40px;
}

.subTopicReportTableContainer thead th {
  padding: 5px;
  color: #828282;
}

.subTopicReportTableContainer thead {
  background-color: #f2f2f2;
}

.subTopicReportThCourse {
  width: 5%;
}

.subTopicReportThSubject {
  width: 7%;
}

.subTopicReportThTopic {
  width: 10%;
}

.subTopicReportThObjective {
  width: 8%;
}

.subTopicReportTableContainer tbody tr {
  height: 30px;
  border-bottom: 1px solid #e0e0e0;
}

.subTopicReportTableContainer tbody tr td {
  border-bottom: 1px solid #e0e0e0;
}
