.tableContainerMigrate {
  max-height: 610px;
  width: 92%;
  margin: 0 5%;
  margin-left: 3.4%;
  margin-top: 20px;
}

.migrateTable {
  max-height: 565px;
  width: auto;
  overflow-y: auto;
  display: block;
  border-spacing: 0px;
}

.migrateTable tbody tr:hover {
  background-color: #cae0ee;
}

.migrateTable tbody tr:active {
  background-color: #71b9e9 !important;
}
.migrateTable tbody tr:visited {
  background-color: #71b9e9 !important;
}

.migrateTable tbody tr td {
  text-align: center;
  padding: 10px;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  cursor: pointer;
  border-bottom: 2px solid #e5e5e5 !important;
}
/* .migrateTable tbody tr:nth-child(even){
 background-color: #E5E5E5;
} */

.migrateTable thead tr th {
  text-align: center;
  position: sticky !important;
  top: 0;
  background-color: #e5e5e5;
  color: #797979;
  padding: 15px 2px;
  width: 102px;
}

.migrateTable thead tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 60px;
  position: sticky;
}

.migrateTable thead tr th:last-child {
  /* border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; */
  width: 225px;
  position: sticky;
}
.child2Migrate {
  width: 108px;
}

.child3Migrate {
  width: 80px;
}

.backlogImageContainer {
  height: 200px;
  display: flex;
  margin: 0 5%;
  justify-content: center;
}

.backlogImage {
  margin-right: 5%;
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.reactLoadingIconMigrate {
  height: 25px !important;
  margin: auto;
  width: 30px !important;
}

.loadingDivMigrate {
  display: flex;
  margin-left: 12%;
  margin-top: 8%;
  height: 30px;
  width: 290px;
  position: absolute;
}

.migrateImageContainer {
  height: 550px;
  display: flex;
  margin: 0 10%;
  justify-content: center;
}

.migrateBigImage {
  width: 100% !important;
  height: 500px !important;
  border-radius: 10px;
  margin-top: 45px;
}

.migrateAddContainer {
  height: 90px;
  display: flex;
  margin: 0 5%;
  justify-content: center;
}

.migrateButton {
  max-height: 35px;
  margin-top: 45px;
  color: #f2f2f2;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  background: #68dff0;
  cursor: pointer;
}
.migrateButton:hover {
  background-color: #51cac2;
}
.migrateButton:active {
  border: 1px solid #03afa9 !important;
}

.migrateLoading {
  max-height: 35px;
  margin-top: 44px;
}

.loadingTextMigrate {
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 4%;
  margin-left: 5px;
}

.loadingTextData {
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 4%;
  width: 180px;
  margin-left: 15px;
}

.selectDiv {
  display: flex;
}

.selectAllDiv {
  display: flex;
  margin-left: 11%;
  margin-right: 2%;
  height: 24px;
  width: 92px;
  max-height: 35px;
  color: #f2f2f2;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  padding: 6px 5px;
  background: #68dff0;
}

.selectAllInput {
  margin-left: 7%;
  margin-top: 5px;
}

.selectAllP {
  margin: 2px;
  margin-top: 4px;
}

.selectedP {
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 8px;
}

.errorP {
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 8px;
  color: red;
}

.loadingDivMigrateAdd {
  display: inline-flex;
  height: 30px;
  width: 290px;
  position: absolute;
  margin-top: 1px;
}

.cancelMigrate {
  color: #fff;
  background-color: #68dff0;
  padding: 4px 12px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
  height: 34px;
  border: none;
}
.cancelMigrate:hover {
  background-color: #51cac2;
}
.cancelMigrate:active {
  border: 1px solid #03afa9 !important;
}

.reactLoadingIconAdd {
  height: 25px !important;
  margin-left: 10px;
  width: 30px !important;
  margin-right: 18px;
  margin-top: 5px;
}

.loadingTextAdd {
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 500;
  margin-right: 4%;
  width: 180px;
}

.loadingTextMigrateError {
  color: red;
  font-family: "Ruda", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 4%;
  margin-left: 5px;
}

.checkboxhid {
  display: none;
}
