.viewH3 {
  font-size: 24px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 70px;
  margin-bottom: 20px;
  color: #797979;
}

.viewH3 .viewH3Arrow {
  font-size: 16px;
}

.viewContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.viewSearch {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
}
.viewSearch:hover {
  background-color: #51cac2;
}

.viewSearch:active {
  background-color: #327e79;
}

.endDiv {
  height: 500px;
  background: white;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 2px #aab2bd;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 0px 3px 2px #aab2bd;
}

.searchQuestionContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
}

.searchQuestionHeading {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Ruda", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.searchQuestionSubContainer {
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.searchQuestionDropdown {
  width: 100%;
  margin-bottom: 20px;
}

.searchQuestionSingleInput {
  width: 50%;
  display: flex;
}

.searchQuestionLabel {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-right: 3%;
  width: 20%;
  margin-left: 9%;
  color: #797979;
  font-family: "Ruda", sans-serif;
}

.searchQuestionSelect {
  box-sizing: border-box;
  width: 52%;
  position: relative;
  max-height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  color: #555;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  margin-left: 4%;
  margin-right: 11%;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline-color: #68dff0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.viewdropdownDiv {
  display: flex;
  margin-bottom: 20px;
}

.viewImageContainer {
  height: 450px;
  display: flex;
  margin: 0 6%;
  justify-content: center;
  margin-bottom: 25px;
}
.viewQuestionDisplayDiv {
  height: 500px;
  overflow: auto;
}

.viewQuestionsBigImage {
  width: 100%;
  height: 440px;
  border-radius: 10px;
  margin-top: 25px;
}

.viewAddContainer {
  height: 80px;
  display: flex;
  margin: 0 5%;
  justify-content: center;
  margin-top: -30px;
}

.compulsoryContainerViewQuestions {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
}

.viewQuestionsSearchDisplay {
  display: flex;
}

.viewTablePagination {
  width: 54%;
}

.viewImageAbove {
  margin-bottom: -5px;
  margin-left: 5px;
}

.viewImageBelow {
  margin-top: 20px;
  width: 50%;
  flex-wrap: wrap;
}

.viewImageBelowTwo {
  margin-top: 0px;
  width: 50%;
  flex-wrap: wrap;
}

.viewImageBelowFile {
  margin-top: 20px;
  cursor: pointer;
  color: #1bc8e1;
  width: 50%;
  flex-wrap: wrap;
}

.viewResultRowDiv {
  display: flex;
  cursor: pointer;
  border-bottom: solid 1px #e5e5e5;
}

.viewResultRowDiv:active {
  background-color: #aab2bd;
}

.viewResultRowDivSel {
  display: flex;
  cursor: pointer;
  border-bottom: solid 1px #e5e5e5;
  background-color: #cae0ee;
}

.viewPublicIcon {
  margin-left: -2px;
  margin-top: 10px;
  color: #68dff0;
}

.viewByLastDiv {
  display: flex;
}

.viewShortDiv {
  margin-left: 20px;
}

.viewShortP {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 700;
}

.viewBy {
  margin-left: 20px;
}

.viewByCurated {
  margin: -4px 0px 10px 38px;
}

.viewLastUpdated {
  margin-left: 25px;
}

.viewQuestionsH4RadioDiv {
  display: flex;
  flex-direction: row;
}

.viewQuestionsRadioDiv {
  margin: -15px 0px 0px 40px;
  display: flex;
}

.viewQuestionsRadioInput {
  margin-left: 20px;
}

.viewQuestionsRadioP {
  margin: 27px 0px 0px 5px;
}

.questionViewdropdownDiv {
  display: flex;
  margin-bottom: 12px;
}

.tableContainerViewQuestion {
  height: 480px;
  margin: 0 5%;
  margin-bottom: 20px;
  overflow-y: auto;
}

.veiwQuestionDisplayInfo {
  display: flex;
  flex-direction: row;
}

.ReuseButton {
  margin-left: 15px;
  max-height: 35px;
  margin-top: 45px;
  color: #f2f2f2;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  background: #68dff0;
  cursor: pointer;
}

.ReuseButton:hover {
  background-color: #51cac2;
}
.ReuseButton:active {
  border: 1px solid #03afa9 !important;
}

.loadingBackground {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 99;
}