.summaryTable {
  display: inline-block;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 96%;
}

.summaryTable,
.summaryTable th,
.summaryTable td {
  border: 1px solid #797979;
  border-collapse: collapse;
  font-weight: 500;
}

.summaryTable th,
.summaryTable td {
  padding: 5px;
  text-align: center;
}

.summaryTable th:nth-child(1),
.summaryTable td:nth-child(1) {
  font-weight: 800;
}


.summaryTableVertical {
  display: inline-block;
  overflow: auto;
  white-space: nowrap;
  max-width: 96%;
  max-height: 400px;
  /* overflow-y: auto; */
}

.summaryTableVertical,
.summaryTableVertical th,
.summaryTableVertical td {
  border: 1px solid #797979;
  border-collapse: collapse;
  /* font-weight: 500; */
}

.summaryTableVertical th,
.summaryTableVertical td {
  padding: 5px;
  text-align: center;
}

.summaryTabletextAlign{
  text-align: left !important;
}

/* .summaryTableVertical th:nth-child(1),
.summaryTableVertical td:nth-child(1) {
  font-weight: 800;
} */