.qSetReportContainer {
    background-color: #f2f2f2;
    margin-left: 15%;
    margin-right: 15%;
  }
  
  .qSetReportH3 {
    font-size: 24px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin-top: 70px;
    margin-bottom: 20px;
    color: #797979;
  }
  
  .qSetReportH3 .qSetReportArrow {
    font-size: 16px;
  }
    
    .qSetReportSearchButtonContainer {
      background: #ffffff;
      padding: 15px;
      margin-bottom: 15px;
      box-sizing: border-box;
      color: #797979;
      font-family: "Ruda", sans-serif;
      font-size: 13px;
      box-shadow: 3px 3px 3px 3px #aab2bd;
      margin-left: 15%;
      margin-right: 15%;
    }
    
    .qSetReportSearchBtn {
      color: #fff;
      background-color: #68dff0;
      box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
      padding: 6px 12px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      border: 1px solid #48bcb4;
      border-radius: 4px;
      cursor: pointer;
    }
    .qSetReportSearchBtn:hover {
      background-color: #51cac2;
    }
    
    .qSetReportSearchBtn:active {
      background-color: #327e79;
    }
    
    .qSetReportSearchContainer {
      background: #ffffff;
      padding: 15px;
      margin-bottom: 15px;
      box-sizing: border-box;
      color: #797979;
      font-family: "Ruda", sans-serif;
      font-size: 13px;
      box-shadow: 3px 3px 3px 3px #aab2bd;
      margin-left: 15%;
      margin-right: 15%;
    }
    
    .qSetReportHeading {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-family: "Ruda", sans-serif;
      font-weight: 500;
      line-height: 1.1;
      color: inherit;
    }
    
    
    .qSetReportLabel {
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 5px;
      font-weight: 500;
      margin-right: 3%;
      width: 20%;
      margin-left: 9%;
      color: #797979;
      font-family: "Ruda", sans-serif;
    }
    
    .qSetReportSelect {
      box-sizing: border-box;
      width: 52%;
      position: relative;
      max-height: 35px;
      padding-right: 15px;
      padding-left: 15px;
      color: #555;
      font-family: "Ruda", sans-serif;
      font-size: 13px;
      line-height: 1.42857143;
      margin-left: 4%;
      margin-right: 11%;
      border-radius: 4px;
      border: 1px solid #ccc;
      outline-color: #68dff0;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
    
    .qSetReportDropdownDiv {
      display: flex;
      margin-bottom: 20px;
    }
    
    .qSetReportloadingDiv {
      display: inline-flex;
      height: 30px;
      width: 290px;
      position: absolute;
    }
  
    .qSetRepReactLoadingIcon {
      height: 25px !important;
      margin-left: 10px;
      width: 30px !important;
      margin-right: 18px;
    }
    
    .qSetRepLoadingTextData {
      color: #797979;
      font-family: "Ruda", sans-serif;
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 5px;
      font-weight: 500;
      margin-right: 4%;
      width: 180px;
      margin-left: 20px;
    }

    .topRepCheckDiv {
      width: 100%;
      margin-bottom: 20px;
    }

    .topRepdropdown {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
    }

    .topRepDropdownLabel {
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 5px;
      font-weight: 500;
      margin-right: 3%;
      width: 20%;
      margin-left: 9%;
      color: #797979;
      font-family: "Ruda", sans-serif;
    }

    .qSetReportCheckbox {
      height: 22px;
      width: 3.2%;
      box-sizing: border-box;
      position: relative;
      max-height: 35px;
      margin-left: 4%;
      margin-right: 11%;
    }
    
    